import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => {
  return {}
}

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, bundleId) {
    try {
      const res = await this.$axios.$get(
        `/bundle/${bundleId}/follow-up-chats/`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async retrieve({ commit }, payload) {
    const { bundleId, chatId, orderId } = payload

    try {
      const res = await this.$axios.$get(
        `/bundle/${bundleId}/follow-up-chats/${chatId}/?order_id=${orderId}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const {
        name,
        textForBankTransfer,
        textForCOD,
        textForEpayment,
        image,
        whatsappIntegration,
        trigger,
        hoursAfter,
        changeStatus,
        bundleId,
        isDefault
      } = payload

      const res = await this.$axios.$post(
        `/bundle/${bundleId}/follow-up-chats/`,
        {
          name,
          text_for_bank_transfer: textForBankTransfer,
          text_for_cod: textForCOD,
          text_for_epayment: textForEpayment,
          image,
          wa_integration: whatsappIntegration,
          trigger,
          hours_after: hoursAfter,
          change_status: changeStatus,
          is_default: isDefault
        }
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const {
        id,
        name,
        textForBankTransfer,
        textForCOD,
        textForEpayment,
        image,
        trigger,
        hoursAfter,
        changeStatus,
        bundleId,
        whatsappIntegration
      } = payload

      const res = await this.$axios.$put(
        `/bundle/${bundleId}/follow-up-chats/${id}/`,
        {
          name,
          text_for_bank_transfer: textForBankTransfer,
          text_for_cod: textForCOD,
          text_for_epayment: textForEpayment,
          image,
          wa_integration: whatsappIntegration,
          trigger,
          hours_after: hoursAfter,
          change_status: changeStatus,
          provider: null
        }
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async setDefault({ commit }, payload) {
    try {
      const { id, bundleId, isDefault } = payload

      const res = await this.$axios.$patch(
        `/bundle/${bundleId}/follow-up-chats/${id}/`,
        {
          is_default: isDefault
        }
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async generate({ commit }, bundleId) {
    try {
      const res = await this.$axios.$post(
        `/bundle/${bundleId}/generate-follow-up-chats/`
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, payload) {
    try {
      const { id, bundleId } = payload
      await this.$axios.$delete(`/bundle/${bundleId}/follow-up-chats/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
