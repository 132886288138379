import {
  encodeQueryData,
  initQueryParams,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

const defaultRequiredFields = [
  'secret_slug',
  'final_variants',
  'store',
  'origin_address',
  'address_location',
  'handler_phone',
  'order_id',
  'destination_address',
  'quantity',
  'product_price',
  'total_weight',
  'courier_service',
  'shipping_cost',
  'payment_method',
  'gross_revenue',
  'shipment_receipt',
  'courier_additional_info',
  'payment_status',
  'status',
  'customer',
  'draft_time',
  'orderlines',
  'is_dropshipping',
  'dropshipper_name',
  'dropshipper_phone',
  'unique_code_discount',
  'tags',
  'awb_status',
  'follow_up_chats',
  'follow_up_chat_type',
  'message_history',
  'store',
  'destination_address',
  'customer'
]

export const state = () => ({
  order: [],
  metaOrder: {
    pageSize: 25,
    lastId: undefined
  },
  statistic: {},
  statisticV2: null,
  statisticV2WithMD: null,
  chart: {},
  columns: [
    {
      name: 'Separator',
      sname: 'General'
    },
    {
      name: 'Pelanggan',
      isVisible: true,
      requestedFields: ['destination_address', 'customer']
    },
    {
      name: 'Status Pesanan',
      isVisible: true,
      requestedFields: ['status', 'final_variants']
    },
    {
      name: 'Status Pembayaran',
      isVisible: true,
      requestedFields: [
        'payment_status',
        'status',
        'final_variants',
        'shipment_account',
        'store',
        'payment_method',
        'payment_account',
        'financial_entity',
        'payment_account_holder',
        'payment_account_number',
        'transferproof_url',
        'transfer_time',
        'product_price',
        'product_discount',
        'shipping_discount',
        'other_income',
        'other_income_name',
        'gross_revenue',
        'shipping_cost'
      ]
    },
    {
      name: 'Status Pickup',
      isVisible: false,
      requestedFields: [
        'awb_status',
        'shipment_account',
        'courier_aggregator',
        'status',
        'final_variants'
      ]
    },
    {
      name: 'Status Pengiriman',
      isVisible: false,
      requestedFields: ['shipment_status']
    },
    {
      name: 'Toko',
      isVisible: true,
      requestedFields: ['store']
    },
    {
      name: 'Produk',
      isVisible: false,
      requestedFields: ['final_variants']
    },
    {
      name: 'Jenis Pesanan',
      isVisible: false,
      requestedFields: ['is_repeat']
    },
    {
      name: 'Platform',
      isVisible: false,
      requestedFields: ['platform']
    },
    {
      name: 'Tags',
      isVisible: false,
      requestedFields: ['tags']
    },
    {
      name: 'External ID',
      isVisible: false,
      requestedFields: ['external_id']
    },
    {
      name: 'Purchase Event Triggered',
      isVisible: false,
      requestedFields: [
        'is_purchase_fb',
        'is_purchase_tiktok',
        'fb_pixel_ids',
        'tiktok_pixel_ids'
      ]
    },
    {
      name: 'Separator',
      sname: 'Waktu'
    },
    {
      name: 'Waktu Dibuat',
      isVisible: false,
      requestedFields: ['draft_time']
    },
    {
      name: 'Waktu Closing',
      isVisible: false,
      requestedFields: ['pending_time']
    },
    {
      name: 'Waktu Dikonfirmasi',
      isVisible: false,
      requestedFields: ['confirmed_time']
    },
    {
      name: 'Waktu Dikirim',
      isVisible: false,
      requestedFields: ['shipped_time']
    },
    {
      name: 'Waktu Selesai',
      isVisible: false,
      requestedFields: ['completed_time']
    },
    {
      name: 'Waktu RTS',
      isVisible: false,
      requestedFields: ['rts_time']
    },
    {
      name: 'Waktu Dibatalkan',
      isVisible: false,
      requestedFields: ['canceled_time']
    },
    {
      name: 'Waktu Ditutup',
      isVisible: false,
      requestedFields: ['closed_time']
    },
    {
      name: 'Separator',
      sname: 'Pengiriman'
    },
    {
      name: 'Kurir',
      isVisible: false,
      requestedFields: ['courier_service']
    },
    {
      name: 'Layanan Kurir',
      isVisible: false,
      requestedFields: ['courier_service']
    },
    {
      name: 'Resi Pengiriman',
      isVisible: false,
      requestedFields: ['shipment_receipt']
    },
    {
      name: 'Berat',
      isVisible: false,
      requestedFields: ['total_weight']
    },
    {
      name: 'Kurir Agregator',
      isVisible: false,
      requestedFields: ['courier_aggregator']
    },
    {
      name: 'Tujuan Pengiriman',
      isVisible: false,
      requestedFields: ['destination_address', 'customer']
    },
    {
      name: 'Asal Pengiriman',
      isVisible: false,
      requestedFields: ['warehouse']
    },
    {
      name: 'Separator',
      sname: 'Marketing & Sales'
    },
    {
      name: 'Landing Page',
      isVisible: false,
      requestedFields: ['page']
    },
    {
      name: 'Channel',
      isVisible: false,
      requestedFields: ['channel_name']
    },
    {
      name: 'Sales Person',
      isVisible: false,
      requestedFields: ['handler']
    },
    {
      name: 'Advertiser',
      isVisible: false,
      requestedFields: ['advertiser']
    },
    {
      name: 'Follow Up Text',
      isVisible: true,
      requestedFields: []
    },
    {
      name: 'Separator',
      sname: 'Pembayaran'
    },
    {
      name: 'Metode Pembayaran',
      isVisible: false,
      requestedFields: [
        'payment_method',
        'xendit_ewallet',
        'pg_payment_info',
        'sub_payment_method'
      ]
    },
    {
      name: 'Penyedia Pembayaran',
      isVisible: false,
      requestedFields: ['financial_entity']
    },
    {
      name: 'Rekening / Akun',
      isVisible: false,
      requestedFields: ['payment_account_holder', 'payment_account_number']
    },
    {
      name: 'Bukti Transfer',
      align: 'center',
      isVisible: true,
      requestedFields: ['transferproof_url']
    },
    {
      name: 'Waktu Transfer',
      isVisible: false,
      requestedFields: ['transfer_time']
    },
    {
      name: 'Invoice Payment Gateway',
      isVisible: false,
      requestedFields: ['invoice_url', 'pg_payment_info']
    },
    {
      name: 'Separator',
      sname: 'Finance'
    },
    {
      name: 'Pendapatan Kotor',
      isVisible: true,
      requestedFields: ['gross_revenue']
    },
    {
      name: 'Pendapatan Bersih',
      isVisible: false,
      requestedFields: ['net_revenue']
    },
    {
      name: 'Harga Produk',
      isVisible: false,
      requestedFields: ['product_price']
    },
    {
      name: 'Diskon Produk',
      isVisible: false,
      requestedFields: ['product_discount']
    },
    {
      name: 'Ongkir',
      isVisible: false,
      requestedFields: ['shipping_cost']
    },
    {
      name: 'Diskon Ongkir',
      isVisible: false,
      requestedFields: ['shipping_discount']
    },
    {
      name: 'Biaya Pembayaran',
      isVisible: false,
      requestedFields: ['payment_fee']
    },
    {
      name: 'Other Income',
      isVisible: false,
      requestedFields: ['other_income']
    },
    {
      name: 'Separator',
      sname: 'UTM'
    },
    {
      name: 'UTM Source',
      isVisible: false,
      requestedFields: ['utm_source']
    },
    {
      name: 'UTM Medium',
      isVisible: false,
      requestedFields: ['utm_medium']
    },
    {
      name: 'UTM Campaign',
      isVisible: false,
      requestedFields: ['utm_campaign']
    },
    {
      name: 'UTM Term',
      isVisible: false,
      requestedFields: ['utm_term']
    },
    {
      name: 'UTM Content',
      isVisible: false,
      requestedFields: ['utm_content']
    }
  ],
  availableWarehouses: [],
  availableCouriers: [],
  orderHandler: [],
  orderPage: [],
  orderAdvertiser: [],
  orderStore: [],
  utmSources: [],
  tags: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state()),
  getOrderCount(state) {
    return state.order.length
  }
}

export const actions = {
  checkAllColumns({ commit, state }) {
    commit(
      'SET_COLUMNS',
      state.columns.map((x) => {
        return {
          ...x,
          isVisible: true
        }
      })
    )
  },

  resetColumns({ commit, state }) {
    const trueByDefault = [
      'Pelanggan',
      'Toko',
      'Status Pesanan',
      'Status Pembayaran',
      'Follow Up Text',
      'Bukti Transfer',
      'Pendapatan Kotor'
    ]
    commit(
      'SET_COLUMNS',
      state.columns.map((x) => {
        return {
          ...x,
          isVisible: trueByDefault.includes(x.name)
        }
      })
    )
  },

  async searchAvailableWarehouses({ commit }, payload) {
    try {
      const { order, store, variants, destination, cancelToken } = payload

      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/search-warehouse/`,
        {
          order_id: order,
          store_id: store,
          variants: variants.map((x) => {
            return { variant_id: x.id, qty: x.qty }
          }),
          destination_id: destination
        },
        {
          cancelToken
        }
      )

      commit('SET_AVAILABLE_WAREHOUSES', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async searchAvailableCouriers({ commit }, payload) {
    try {
      const {
        store,
        paymentMethod,
        destination,
        warehouse,
        weight,
        cancelToken
      } = payload
      const url = `${this.$config.apiUrlV2}/order/search-courier-service/`
      const res = await this.$axios.$post(
        url,
        {
          store_id: store,
          payment_method: paymentMethod,
          location_id: destination,
          warehouse_id: warehouse,
          weight
        },
        {
          cancelToken
        }
      )

      commit('SET_AVAILABLE_COURIERS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/order/${id}/`
      const resOrder = await this.$axios.$get(url)
      return resOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchByPublicSecretSlug({ commit }, secretSlug) {
    try {
      const url = `${this.$config.apiUrlV2}/order/public/${secretSlug}/`
      const resOrder = await this.$axios.$get(url)
      return resOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchPublic({ commit }, payload) {
    try {
      const { username, secretSlug } = payload
      const url = `/order/public-v2/${username}/${secretSlug}/`
      const resOrder = await this.$axios.$get(url)
      return resOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchPublicARecord({ commit }, payload) {
    try {
      const { customDomain, secretSlug } = payload
      const url = `/order/publicv-v2/${customDomain}/${secretSlug}/`
      const resOrder = await this.$axios.$get(url)
      return resOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllPaginated(
    { commit, state },
    { query = {}, isFirst = false, cancelToken } = {}
  ) {
    if (isFirst) {
      commit('SET_ORDER', [])
      commit('SET_META_ORDER', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.metaOrder
    const requestedFields = [...defaultRequiredFields]
    state.columns
      .filter((x) => x.isVisible && x.name !== 'Separator')
      .forEach((x) => {
        x.requestedFields.forEach((y) => {
          if (!requestedFields.includes(y)) {
            requestedFields.push(y)
          }
        })
      })
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId,
      columns: requestedFields.join(','),
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/order/?${queries}`
    try {
      const resOrder = await this.$axios.$get(url, { cancelToken })
      const allOrder = [...state.order, ...resOrder.data.results]
      commit('SET_ORDER', allOrder)
      const nextMeta = {
        pageSize,
        lastId:
          resOrder.data.results[resOrder.data.results.length - 1]?.id ||
          undefined
      }
      commit('SET_META_ORDER', nextMeta)
      return resOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchStatistic({ commit }, query) {
    let queries = ''
    queries = `?${encodeQueryData({
      ...query,
      is_breakdown_status: true
    })}`
    try {
      const resStatistic = await this.$axios.$get(
        `${this.$config.apiUrlV2}/order-statistics/${queries}`
      )
      commit('SET_STATISTIC', resStatistic.data)
      return resStatistic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchStatisticForDashboard({ commit }, query) {
    let queries = ''
    queries = `?${encodeQueryData({
      ...query
    })}`
    try {
      const resStatistic = await this.$axios.$get(
        `${this.$config.apiUrlV2}/order-statistics/${queries}`
      )
      commit('SET_STATISTIC_V_2', resStatistic.data)
      return resStatistic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchStatisticForDashboardMD({ commit }, query) {
    let queries = ''
    queries = `?${encodeQueryData({
      ...query,
      minimum_days: 30
    })}`
    try {
      const resStatistic = await this.$axios.$get(
        `${this.$config.apiUrlV2}/order-statistics/${queries}`
      )
      commit('SET_STATISTIC_V_2_WITH_MD', resStatistic.data)
      return resStatistic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchStatisticV2({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resStatistic = await this.$axios.$get(
        `/order/statistic-v2/${queries}`
      )
      commit('SET_STATISTIC_V_2', resStatistic.data)
      return resStatistic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetStatistic({ commit }) {
    commit('SET_STATISTIC', {})
    commit('SET_STATISTIC_V_2', null)
    commit('SET_STATISTIC_V_2_WITH_MD', null)
  },

  async downloadStatistic({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resDownloadStatistic = await this.$axios({
        url: `/order/download-statistic/${queries}`,
        method: 'get',
        responseType: 'blob'
      })
      return resDownloadStatistic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchChart({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resChart = await this.$axios.$get(`/order/chart/${queries}`)
      commit('SET_CHART', resChart.data)
      return resChart
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchKillPagination({ commit }) {
    try {
      const resOrderList = await this.$axios.$get('/order/')
      commit('SET_SHIPMENT', [])
      return resOrderList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchAllMessageHistory({ commit }, id) {
    try {
      const res = await this.$axios.$get(`/order/${id}/message-history/`)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchUtmSources({ commit }) {
    try {
      const res = await this.$axios.$get(`/order/utm-source/`)
      commit('SET_UTM_SOURCES', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchTags({ commit }) {
    try {
      const res = await this.$axios.$get(`/order/tags/`)
      commit('SET_TAGS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  fetchChats({ commit }, id) {
    try {
      const res = this.$axios.$get(`${this.$config.apiUrlV2}/order/${id}/chats`)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  fetchChatById({ commit }, payload) {
    const { id, followUpChatId } = payload
    try {
      const res = this.$axios.$get(
        `${this.$config.apiUrlV2}/order/${id}/chat/${followUpChatId}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  fetchActions({ commit }, id) {
    try {
      const res = this.$axios.$get(
        `${this.$config.apiUrlV2}/order/${id}/actions`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async addMessageHistory({ commit }, payload) {
    try {
      const { id, followUpChat } = payload
      const res = await this.$axios.$put(`/order/${id}/message-history/`, {
        follow_up_chat: followUpChat
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async lead({ commit }, payload) {
    const {
      formId,
      name,
      phone,
      email,
      subdistrictId,
      address,
      notes,
      bundleId,
      variantId,
      eventSourceUrl,
      fbc,
      fbp,
      ip,
      ua,
      lpOrigin,
      referer
    } = payload
    const filteredData = Object.fromEntries(
      Object.entries({
        form: formId,
        name,
        phone,
        email,
        location: subdistrictId,
        address,
        notes,
        bundle: bundleId,
        variant: variantId,
        event_source_id: eventSourceUrl,
        fbc,
        fbp,
        ip,
        ua,
        lp_origin: lpOrigin,
        referer
      }).filter(([_, v]) => v !== null && v !== '')
    )
    try {
      const resLead = await this.$axios.$post('/order/lead/', filteredData)
      return resLead
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadTransferProof({ commit }, transferProof) {
    try {
      const formData = new FormData()
      formData.append('image', transferProof)
      const resUploadTransferProof = await this.$axios.$post(
        '/transfer-proof/',
        formData
      )
      return resUploadTransferProof
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadRtsProof({ commit }, rtsProof) {
    try {
      const formData = new FormData()
      formData.append('image', rtsProof)
      const resUploadRtsProof = await this.$axios.$post('/rts-proof/', formData)
      return resUploadRtsProof
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData(_store, payload) {
    try {
      const {
        form,
        page,
        store,
        customerName,
        customerPhone,
        customerEmail,
        name,
        phone,
        address,
        location,
        paymentMethod,
        financialEntityId,
        paymentAccount,
        paymentAccountHolder,
        paymentAccountNumber,
        shippingOrigin,
        shipmentAccount,
        courierService,
        otherIncome,
        otherIncomeName,
        orderlines,
        productDiscount,
        shippingCost,
        shippingDiscount,
        notes,
        transferProof,
        ordervariants,
        orderbundles,
        eventSourceUrl,
        fbc,
        fbp,
        ttclid,
        gclid,
        ttp,
        ip,
        ua,
        originSite,
        referer,
        adSource,
        channel,
        courierAggregator,
        paymentFee,
        paymentFeeRate,
        minPaymentFee,
        subPaymentMethod,
        pageReferrers,
        uniqueCodeDiscount,
        isDropshipping,
        dropshipperName,
        dropshipperPhone,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm
      } = payload

      const filteredData = Object.fromEntries(
        Object.entries({
          form,
          page_unique_id: page,
          store_unique_id: store,
          customer_name: customerName,
          customer_phone: customerPhone,
          customer_email: customerEmail,
          name,
          phone,
          address,
          location_id: location,
          payment_method: paymentMethod,
          financial_entity_id: financialEntityId,
          payment_account_unique_id: paymentAccount,
          payment_account_holder: paymentAccountHolder,
          payment_account_number: paymentAccountNumber,
          warehouse_unique_id: shippingOrigin,
          shipment_account: shipmentAccount,
          courier_service_id: courierService,
          other_income: otherIncome,
          other_income_name: otherIncomeName,
          orderlines,
          product_discount: productDiscount,
          shipping_cost: shippingCost,
          shipping_discount: shippingDiscount,
          notes,
          transferproof_url: transferProof,
          ordervariants,
          orderbundles,
          event_source_url: eventSourceUrl,
          fbc,
          fbp,
          ttclid,
          gclid,
          ttp,
          ip,
          ua,
          origin_site: originSite,
          referer,
          ad_source: adSource,
          channel_unique_id: channel,
          courier_aggregator_id: courierAggregator,
          payment_fee: paymentFee,
          payment_fee_rate: paymentFeeRate,
          min_payment_fee: minPaymentFee,
          sub_payment_method: subPaymentMethod,
          page_referrers: pageReferrers,
          unique_code_discount: uniqueCodeDiscount,
          is_dropshipping: isDropshipping,
          dropshipper_name: dropshipperName,
          dropshipper_phone: dropshipperPhone,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          utm_term: utmTerm
        }).filter(([_, v]) => v !== null && v !== '')
      )

      const url = `${this.$config.apiUrlV2}/order/`
      const resNewOrder = await this.$axios.$post(url, filteredData)
      return resNewOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const {
        id,
        store,
        customerName,
        customerPhone,
        customerEmail,
        name,
        phone,
        address,
        location,
        paymentMethod,
        financialEntityId,
        paymentAccount,
        paymentAccountHolder,
        paymentAccountNumber,
        shippingOrigin,
        shipmentAccount,
        courierService,
        otherIncome,
        otherIncomeName,
        orderlines,
        productDiscount,
        shippingCost,
        shippingDiscount,
        notes,
        transferProof,
        ordervariants,
        orderbundles,
        form,
        page,
        adSource,
        channel,
        courierAggregator,
        paymentFee,
        paymentFeeRate,
        minPaymentFee,
        subPaymentMethod,
        uniqueCodeDiscount,
        isDropshipping,
        dropshipperName,
        dropshipperPhone
      } = payload
      const url = `${this.$config.apiUrlV2}/order/${id}`
      const resUpdateOrder = await this.$axios.$patch(url, {
        store_unique_id: store,
        customer_name: customerName,
        customer_phone: customerPhone,
        customer_email: customerEmail,
        name,
        phone,
        address,
        location_id: location,
        payment_method: paymentMethod,
        financial_entity_id: financialEntityId,
        payment_account_unique_id: paymentAccount,
        payment_account_holder: paymentAccountHolder,
        payment_account_number: paymentAccountNumber,
        warehouse_unique_id: shippingOrigin,
        shipment_account: shipmentAccount,
        courier_service_id: courierService,
        other_income: otherIncome,
        other_income_name: otherIncomeName,
        orderlines,
        product_discount: productDiscount,
        shipping_cost: shippingCost,
        shipping_discount: shippingDiscount,
        notes,
        transferproof_url: transferProof,
        ordervariants,
        orderbundles,
        form,
        page_unique_id: page,
        ad_source: adSource,
        channel_unique_id: channel,
        courier_aggregator_id: courierAggregator,
        payment_fee: paymentFee,
        payment_fee_rate: paymentFeeRate,
        min_payment_fee: minPaymentFee,
        subPaymentMethod,
        unique_code_discount: uniqueCodeDiscount,
        is_dropshipping: isDropshipping,
        dropshipper_name: dropshipperName,
        dropshipper_phone: dropshipperPhone
      })
      return resUpdateOrder
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changeStatus({ commit }, payload) {
    const {
      status,
      id,
      store,
      customerName,
      customerPhone,
      customerEmail,
      name,
      phone,
      address,
      location,
      paymentMethod,
      financialEntityId,
      paymentAccount,
      paymentAccountHolder,
      paymentAccountNumber,
      shippingOrigin,
      shipmentAccount,
      courierService,
      otherIncome,
      otherIncomeName,
      orderlines,
      productDiscount,
      shippingCost,
      shippingDiscount,
      notes,
      transferProof,
      ordervariants,
      orderbundles,
      form,
      page,
      adSource,
      channel,
      courierAggregator,
      paymentFee,
      paymentFeeRate,
      minPaymentFee,
      subPaymentMethod,
      uniqueCodeDiscount,
      isDropshipping,
      dropshipperName,
      dropshipperPhone,
      transferTime,
      paymentStatus
    } = payload

    try {
      const resChangeOrderStatus = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/bulk/${id}/`,
        {
          status,
          store,
          customer_name: customerName,
          customer_phone: customerPhone,
          customer_email: customerEmail,
          name,
          phone,
          address,
          location,
          payment_method: paymentMethod,
          financial_entity_id: financialEntityId,
          payment_account: paymentAccount,
          payment_account_holder: paymentAccountHolder,
          payment_account_number: paymentAccountNumber,
          warehouse: shippingOrigin,
          shipment_account: shipmentAccount,
          courier_service: courierService,
          other_income: otherIncome,
          other_income_name: otherIncomeName,
          orderlines,
          product_discount: productDiscount,
          shipping_cost: shippingCost,
          shipping_discount: shippingDiscount,
          notes,
          transferproof_url: transferProof,
          ordervariants,
          orderbundles,
          form,
          page,
          ad_source: adSource,
          channel,
          courier_aggregator: courierAggregator,
          payment_fee: paymentFee,
          payment_fee_rate: paymentFeeRate,
          min_payment_fee: minPaymentFee,
          subPaymentMethod,
          unique_code_discount: uniqueCodeDiscount,
          is_dropshipping: isDropshipping,
          dropshipper_name: dropshipperName,
          dropshipper_phone: dropshipperPhone,
          transfer_time: transferTime,
          payment_status: paymentStatus
        }
      )
      return resChangeOrderStatus
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changeStatusBulk({ commit }, payload) {
    const { ids, status } = payload

    try {
      const resChangeOrderStatusBulk = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/bulk/${ids}/`,
        {
          status
        }
      )
      return resChangeOrderStatusBulk
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changePaymentStatus({ commit }, payload) {
    const {
      id,
      paymentStatus,
      transferProof,
      financialEntity,
      paymentAccountHolder,
      paymentAccountNumber,
      status,
      transferTime,
      paymentMethod,
      paymentAccount
    } = payload

    try {
      const changePaymentStatusRes = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/bulk/${id}/`,
        {
          payment_status: paymentStatus,
          transferproof_url: transferProof,
          payment_account: paymentAccount,
          financial_entity_id: financialEntity,
          payment_account_holder: paymentAccountHolder,
          payment_account_number: paymentAccountNumber,
          status,
          transfer_time: transferTime,
          payment_method: paymentMethod
        }
      )
      return changePaymentStatusRes
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changePaymentStatusBulk({ commit }, payload) {
    const { ids, paymentStatus } = payload

    try {
      const changePaymentStatusRes = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/bulk/${ids}/`,
        {
          payment_status: paymentStatus
        }
      )
      return changePaymentStatusRes
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async markOrderAsRTS({ commit }, payload) {
    const { id, rtsProof } = payload

    try {
      const resMarkOrderAsRTS = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/bulk/${id}/`,
        {
          status: 'rts',
          rtsproof_url: rtsProof
        }
      )
      return resMarkOrderAsRTS
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // async markOrderAsRTSBulk({ commit }, ids) {
  //   try {
  //     const resMarkOrderAsRTS = await this.$axios.$put(
  //       `/order/bulk/${ids}/rts/`
  //     )
  //     return resMarkOrderAsRTS
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       throw new Error('Bad Credentials')
  //     } else if (error.response?.status === 502) {
  //       throw new Error('Network Error')
  //     }
  //     throw error
  //   }
  // },

  async updateTags({ commit }, payload) {
    const { id, tags } = payload
    try {
      const res = await this.$axios.$patch(`/order/${id}/tags/`, {
        tags
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateReceipt({ commit }, payload) {
    const { id, shipmentReceipt } = payload
    try {
      const res = await this.$axios.$put(
        `${this.$config.apiUrlV2}/order/${id}/shipment-receipt/`,
        {
          shipment_receipt: shipmentReceipt
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async manualTriggerEventPurchae({ commit }, id) {
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/${id}/trigger-purchase/`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async generateAWB({ commit }, ids) {
    try {
      const resGenerateAWB = await this.$axios.$post(`/order/create-awb/`, {
        order_id: ids
      })
      return resGenerateAWB
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async sendDigitalProduct({ _commit }, id) {
    try {
      const resSendDigitalProductBulk = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/${id}/send-product-digital/`
      )
      return resSendDigitalProductBulk
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updatePublic({ commit }, payload) {
    try {
      const {
        secretSlug,
        transferProofUrl,
        transferTime,
        paymentAccount,
        financialEntity,
        paymentAccountHolder,
        paymentAccountNumber,
        paymentStatus,
        paymentMethod
      } = payload
      const resUpdatePublic = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/order/public/${secretSlug}/`,
        {
          transferproof_url: transferProofUrl,
          transfer_time: transferTime,
          payment_account: paymentAccount,
          financial_entity_id: financialEntity,
          payment_account_holder: paymentAccountHolder,
          payment_account_number: paymentAccountNumber,
          payment_status: paymentStatus,
          payment_method: paymentMethod
        }
      )
      return resUpdatePublic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async cancelAWB({ commit }, ids) {
    try {
      const resCancelAWB = await this.$axios.$post(`/order/cancel-awb/`, {
        order_id: ids
      })
      return resCancelAWB
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async download({ commit }, payload) {
    try {
      const { type, query } = payload
      const queries = encodeQueryData(query)
      let _url = ''
      switch (type) {
        case 'jne':
          _url = `/order/download-jne/?${queries}`
          break
        case 'ninja':
          _url = `/order/download-ninja/?${queries}`
          break
        case 'shipment':
          _url = `/order/download-handover/?${queries}`
          break
        case 'journal':
          _url = `/order/download-journal/?${queries}`
          break
        case 'default':
          _url = `/order/download/?${queries}`
          break
        case 'defaultWithProductRow':
          _url = `/order/download-product-based/?${queries}`
          break
        case 'defaultWithProductColumn':
          _url = `/order/download-with-product/?${queries}`
          break
        case 'customerFileFacebook':
          _url = `/order/download-fb/?${queries}`
          break
        case 'google':
          _url = `/order/download-google/?${queries}`
          break
        default:
          break
      }
      const resOrderDownload = await this.$axios({
        url: _url,
        method: 'get',
        responseType: 'blob'
      })
      return resOrderDownload
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadUpdateStatus({ commit }, payload) {
    try {
      const { file, tz } = payload
      const formData = new FormData()
      formData.append('file', file)
      formData.append('tz', tz)
      const resUploadUpdateStatus = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/upload-change-status`,
        formData
      )
      return resUploadUpdateStatus
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadMarketplace({ commit }, payload) {
    try {
      const { file, type, tz } = payload
      const formData = new FormData()
      formData.append('file', file)
      formData.append('create_type', type)
      formData.append('tz', tz)
      const resUploadMarketplace = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/bulk`,
        formData
      )
      return resUploadMarketplace
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadReceipt({ commit }, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/order/upload-receipt/`,
        formData
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, ids) {
    const url = `${this.$config.apiUrlV2}/order/${ids}`
    try {
      return await this.$axios.$delete(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  setColumns({ commit }, columns) {
    commit('SET_COLUMNS', columns)
  },

  async fetchHandler({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resOrderHandler = await this.$axios.$get(
        `/order/handler/${queries}`
      )
      commit('SET_ORDER_HANDLER', resOrderHandler.data.results)
      return resOrderHandler
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchPage({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resOrderPage = await this.$axios.$get(`/order/page/${queries}`)
      commit('SET_ORDER_PAGE', resOrderPage.data.results)
      return resOrderPage
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAdvertiser({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resOrderAdv = await this.$axios.$get(`/order/advertiser/${queries}`)
      commit('SET_ORDER_ADVERTISER', resOrderAdv.data.results)
      return resOrderAdv
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchStore({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resOrderStore = await this.$axios.$get(`/order/store/${queries}`)
      commit('SET_ORDER_STORE', resOrderStore.data.results)
      return resOrderStore
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
