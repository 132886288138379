import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  stores: [],
  metaStore: [],
  storeCount: 0,
  store: {},
  isAllDataLoaded: false,
  loading: false
})

export const mutations = {
  ...mapMutationsHelper(state()),
  SET_IS_OPEN_BY_INDEX(state, { index, isOpen }) {
    state.stores[index].is_open = isOpen
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_STORES', [])
      commit('SET_META_STORE', [])
      commit('SET_STORE_COUNT', 0)
      commit('SET_STORE', {})
      commit('SET_IS_ALL_DATA_LOADED', false)
      commit('SET_LOADING', false)
    }

    const { page, pageSize } = state.metaStore
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/store/?${queries}`
    try {
      const resStoresList = await this.$axios.$get(url)
      const allStores = [...state.stores, ...resStoresList.data.results]
      commit('SET_STORE_COUNT', resStoresList.data.count)
      commit('SET_STORES', allStores)
      let newMeta = {
        itemsLength:
          resStoresList.data.count || resStoresList.data.results.length
      }
      const nextMeta = resStoresList.data.next
        ? decodeQueryData(resStoresList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_STORE', newMeta)
      return { totalCount: resStoresList.data.count, currentData: allStores }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  fetchKillPagination({ commit }) {
    commit('SET_LOADING', false)
    try {
      commit('SET_STORES', [])
      commit('SET_META_STORE', {
        page: 1
      })
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchAllPaginated(
    { commit, state },
    { query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_STORES', [])
      commit('SET_META_STORE', [])
      commit('SET_STORE_COUNT', 0)
      commit('SET_STORE', {})
      commit('SET_IS_ALL_DATA_LOADED', false)
      commit('SET_LOADING', false)
    }

    const { page, itemsLength } = state.metaStore
    if (!isFirst && itemsLength === state.stores?.length) {
      return state.stores
    }
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/store/?${queries}`
    try {
      const resStore = await this.$axios.$get(url)
      const allStore = [...state.stores, ...resStore.data.results]
      commit('SET_STORE_COUNT', resStore.data.count)
      commit('SET_STORES', allStore)
      const newMeta = decodeQueryData(resStore.data.next?.split('?')?.[1])
      if (newMeta) {
        commit('SET_META_STORE', {
          itemsLength: resStore.data.count || resStore.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return resStore
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const resFetchById = await this.$axios.$get(`/store/${id}/`)
      commit('SET_STORE', resFetchById.data)
      return resFetchById
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async countStore() {
    try {
      const resStoreCount = await this.$axios.$get(`/store/?page_size=1`)
      return resStoreCount.data.count
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async countProduct() {
    try {
      const resProductCount = await this.$axios.$get(`/product/?page_size=1`)
      return resProductCount.data.count
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const {
        isOpen,
        storeType,
        name,
        products,
        bundlePriceOptions,
        paymentMethods,
        paymentAccounts,
        // storeSalesPerson,
        // storeAdmins,
        // advertisers,
        // autoOtherIncome,
        // fixedAutoOtherIncome,
        // otherIncomeName,
        // isAutoExpire,
        // autoExpireDays,
        // isAdvancedValidation,
        // minNameLength,
        // minAddressLength,
        // maxPerPhone,
        // maxPerIP,
        // itemType,
        // isEmailNotifications,
        // emailNotificationStatuses,
        // isEmailNewOrder,
        // emailReplyToAssignment,
        // emailReplyTo,
        courierServices,
        // isDiscountEditable,
        xenditVaBankCodes,
        xenditEwallets
        // warehouse
      } = payload
      const resUpdateStore = await this.$axios.$post(`/store/`, {
        is_open: isOpen,
        store_type: storeType,
        name,
        products,
        bundle_price_options: bundlePriceOptions,
        payment_methods: paymentMethods,
        payment_accounts: paymentAccounts,
        store_sales_person: [],
        store_admins: [],
        advertisers: [],
        // auto_other_income: autoOtherIncome,
        // fixed_auto_other_income: fixedAutoOtherIncome,
        // other_income_name: otherIncomeName,
        // is_auto_expire: isAutoExpire,
        // auto_expire_days: autoExpireDays,
        // is_advanced_validation: isAdvancedValidation,
        // min_name_length: minNameLength,
        // min_address_length: minAddressLength,
        // max_per_phone: maxPerPhone,
        // max_per_ip: maxPerIP,
        // item_type: itemType,
        // is_email_notifications: isEmailNotifications,
        // email_notification_statuses: emailNotificationStatuses,
        // is_email_new_order: isEmailNewOrder,
        // email_reply_to_assignment: emailReplyToAssignment,
        // email_reply_to: emailReplyTo,
        courier_services: courierServices,
        // is_discount_editable: isDiscountEditable,
        xendit_va_bank_codes: xenditVaBankCodes,
        xendit_ewallets: xenditEwallets
        // warehouse
      })
      return resUpdateStore
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const {
        id,
        isOpen,
        storeType,
        name,
        products,
        bundlePriceOptions,
        paymentMethods,
        paymentAccounts,
        storeSalesPerson,
        storeAdmins,
        advertisers,
        shipmentAccounts,
        autoOtherIncome,
        fixedAutoOtherIncome,
        otherIncomeName,
        isAutoExpire,
        autoExpireDays,
        isAdvancedValidation,
        minNameLength,
        minAddressLength,
        maxPerPhone,
        maxPerIP
      } = payload
      const resUpdateStore = await this.$axios.$put(`/store/${id}/`, {
        is_open: isOpen,
        store_type: storeType,
        name,
        products,
        bundle_price_options: bundlePriceOptions,
        payment_methods: paymentMethods,
        payment_accounts: paymentAccounts,
        store_sales_person: storeSalesPerson,
        store_admins: storeAdmins,
        advertisers,
        shipment_accounts: shipmentAccounts,
        auto_other_income: autoOtherIncome,
        fixed_auto_other_income: fixedAutoOtherIncome,
        other_income_name: otherIncomeName,
        is_auto_expire: isAutoExpire,
        auto_expire_days: autoExpireDays,
        is_advanced_validation: isAdvancedValidation,
        min_name_length: minNameLength,
        min_address_length: minAddressLength,
        max_per_phone: maxPerPhone,
        max_per_ip: maxPerIP
      })
      return resUpdateStore
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async setIsOpen({ state, commit, dispatch }, { index, isOpen }) {
    try {
      const currentStore = state.stores[index]
      await dispatch('partialUpdate', {
        id: currentStore.id,
        isOpen
      })
      commit('SET_IS_OPEN_BY_INDEX', { index, isOpen })
    } catch (error) {
      commit('SET_IS_OPEN_BY_INDEX', { index, isOpen: !isOpen })
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async setCancelIsOpen({ state, commit, dispatch }, { index, isOpen }) {
    try {
      const currentStore = state.stores[index]
      await dispatch('partialUpdate', {
        id: currentStore.id,
        isOpen
      })
      commit('SET_IS_OPEN_BY_INDEX', { index, isOpen: !isOpen })
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async partialUpdate({ commit }, payload) {
    try {
      const {
        id,
        isOpen,
        storeType,
        name,
        products,
        bundlePriceOptions,
        paymentMethods,
        paymentAccounts,
        storeSalesPerson,
        storeAdmins,
        advertisers,
        autoOtherIncome,
        fixedAutoOtherIncome,
        otherIncomeName,
        isAutoExpire,
        autoExpireDays,
        isAdvancedValidation,
        minNameLength,
        minAddressLength,
        maxPerPhone,
        maxPerIP,
        marketingProgram,
        isEmailNotifications,
        emailNotificationStatuses,
        isEmailNewOrder,
        emailReplyToAssignment,
        emailReplyTo,
        courierServices,
        isDiscountEditable,
        xenditVaBankCodes,
        xenditEwallets,
        warehouse,
        isUniqueCode,
        maxUniqueCodeAmount,
        isDropshippingAllowed,
        isTransferproofRequired,
        isMootaEnabled,
        storeCustomAudiences,
        nonCodPurchaseTriggers,
        codPurchaseTriggers,
        dynamicOtherIncomeCodType,
        dynamicOtherIncomeCodDefaultAmount,
        dynamicOtherIncomeCodDefaultPercentage,
        dynamicOtherIncomeEpaymanetType,
        dynamicOtherIncomeEpaymentDefaultAmount,
        dynamicOtherIncomeEpaymentDefaultPercentage,
        storeCourierOtherIncomes,
        storeMethodOtherIncomes,
        capiPurchaseValueType,
        digitalOnlyCompleteType,
        isFucProductEnabled,
        isFucBundleEnabled
      } = payload
      const resUpdateStore = await this.$axios.$patch(`/store/${id}/`, {
        is_open: isOpen,
        store_type: storeType,
        name,
        products,
        bundle_price_options: bundlePriceOptions,
        payment_methods: paymentMethods,
        payment_accounts: paymentAccounts,
        store_sales_person: storeSalesPerson,
        store_admins: storeAdmins,
        advertisers,
        auto_other_income: autoOtherIncome,
        fixed_auto_other_income: fixedAutoOtherIncome,
        other_income_name: otherIncomeName,
        is_auto_expire: isAutoExpire,
        auto_expire_days: autoExpireDays,
        is_advanced_validation: isAdvancedValidation,
        min_name_length: minNameLength,
        min_address_length: minAddressLength,
        max_per_phone: maxPerPhone,
        max_per_ip: maxPerIP,
        marketing_program: marketingProgram,
        is_email_notifications: isEmailNotifications,
        email_notification_statuses: emailNotificationStatuses,
        is_email_new_order: isEmailNewOrder,
        email_reply_to_assignment: emailReplyToAssignment,
        email_reply_to: emailReplyTo,
        courier_services: courierServices,
        is_discount_editable: isDiscountEditable,
        xendit_va_bank_codes: xenditVaBankCodes,
        xendit_ewallets: xenditEwallets,
        warehouse,
        is_unique_code: isUniqueCode,
        max_unique_code_amount: maxUniqueCodeAmount,
        is_dropshipping_allowed: isDropshippingAllowed,
        is_transferproof_required: isTransferproofRequired,
        is_moota_enabled: isMootaEnabled,
        store_custom_audiences: storeCustomAudiences,
        non_cod_purchase_triggers: nonCodPurchaseTriggers,
        cod_purchase_triggers: codPurchaseTriggers,
        dynamic_other_income_cod_type: dynamicOtherIncomeCodType,
        dynamic_other_income_cod_default_amount:
          dynamicOtherIncomeCodDefaultAmount,
        dynamic_other_income_cod_default_percentage:
          dynamicOtherIncomeCodDefaultPercentage,
        dynamic_other_income_epayment_type: dynamicOtherIncomeEpaymanetType,
        dynamic_other_income_epayment_default_amount:
          dynamicOtherIncomeEpaymentDefaultAmount,
        dynamic_other_income_epayment_default_percentage:
          dynamicOtherIncomeEpaymentDefaultPercentage,
        store_courier_other_incomes: storeCourierOtherIncomes,
        store_method_other_incomes: storeMethodOtherIncomes,
        capi_purchase_value_type: capiPurchaseValueType,
        digital_only_complete_type: digitalOnlyCompleteType,
        is_fuc_product_enabled: isFucProductEnabled,
        is_fuc_bundle_enabled: isFucBundleEnabled
      })
      return resUpdateStore
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async destroyStore(_, idStore) {
    try {
      const resDestroyStore = await this.$axios.$delete(`store/${idStore}/`)
      return resDestroyStore
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
